import React from 'react'
import * as stylesFooter from '@styles/layout/footer.module.css'

export default function Footer() {
  return (
    <footer className={stylesFooter.container}>
      <div>
        <img className={stylesFooter.imgLogo}
              src="/media/images/general/sovos.svg"
              alt="Sovos"
              style={{ width:"201px" }}
            />
      </div>
      <div>
        <p>
          <a href={process.env.URL_FACEBOOK} target="_blank" rel="noreferrer">
            <img className={stylesFooter.iconFbSize}
              src="/media/images/social-networks/icon_fb.svg"
              alt="facebook"
            />
          </a>
          <a
            href={process.env.URL_LINKEDIN}
            target="_blank"
            rel="noreferrer"
          >
            <img className={stylesFooter.iconInSize}
              src="/media/images/social-networks/icon_in.svg"
              alt="linkedin"
            />
          </a>
          <a
            href={process.env.URL_SOVOS}
            target="_blank"
            rel="noreferrer"
            className={stylesFooter.a}
          >
            www.sovos.com/es/
          </a>
        </p>
      </div>

      <div>
        <p>Information Security</p>
        <p>Management System ISO</p>
        <p>27001:2013</p>
      </div>

      <div>
        <a
          href={process.env.URL_CONTACTS}
          target="_blank"
          rel="noreferrer"
          className={stylesFooter.a}
        >
          <p>Contáctanos&nbsp;&nbsp;|&nbsp;&nbsp;&copy; 2021 Sovos</p>
        </a>
      </div>
    </footer>
  )
}
